import {Task, TaskApi} from "./Model";
import {LocalDate, ZonedDateTime} from "@js-joda/core";
import {uuid} from "uuidv4";
import {dateTime, localDate} from "../Core/Util/DateUtils";

export function mapTask(api: TaskApi): Task {
  return {
    id: api.id,
    details: api.details,
    deleted: api.deleted,
    dateStart: LocalDate.parse(api.dateStart),
    dateEnd: api.dateEnd ? LocalDate.parse(api.dateEnd) : null,
    dateCreated: ZonedDateTime.parse(api.dateCreated),
    dateUpdated: ZonedDateTime.parse(api.dateUpdated)
  }
}

export function createTask(): Task {
  return {
    id: uuid(),
    details: '',
    deleted: false,
    dateStart: localDate(),
    dateEnd: null,
    dateCreated: dateTime(),
    dateUpdated: dateTime(),
    creating: true
  }
}

export function sortTasks(tasks: Array<Task>): Array<Task> {
  let today = LocalDate.now().toEpochDay()
  
  let current = tasks.filter((task) => isCurrent(today, task))
  let history = tasks.filter((task) => !isCurrent(today, task))

  current.forEach((task) => task.current = true)
  history.forEach((task) => task.current = false)

  return sort(current).concat(sort(history))
}

function isCurrent(today: number, task: Task): boolean {
  return task.dateStart.toEpochDay() === today
      || (task.dateStart.toEpochDay() <= today && (task.dateEnd ? task.dateEnd.toEpochDay() : 0) >= today)
}

function sort(tasks: Array<Task>): Array<Task> {
  return tasks.sort((a, b) => {
    return b.dateStart.toEpochDay() - a.dateStart.toEpochDay()
  })
}
