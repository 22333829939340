import React from "react";
import {useHistory} from "react-router-dom";
import {clearCredentials, Path} from "../App";
import {Nav, Navbar} from "react-bootstrap";

const NavBar: React.FunctionComponent = () => {
  const history = useHistory();
  
  const onLogout = () => {
    clearCredentials()
    history.push(Path.Login)
  }
  
  const onMyTasks = () => {
    history.push(Path.Tasks.Me)
  }
  
  const onPartnersTasks = () => {
    history.push(Path.Tasks.Partner)
  }
  
  const onProfile = () => {
    history.push(Path.Profile)
  }
  
  return (
      <Navbar bg="primary" variant="dark" expand="md">
        <Navbar.Brand href="/">Partners Accountability</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
          </Nav>
          <Nav>
            <Nav.Link onClick={onMyTasks}>My Tasks</Nav.Link>
            <Nav.Link onClick={onPartnersTasks}>Partner's Tasks</Nav.Link>
            <Nav.Link onClick={onProfile}>Profile</Nav.Link>
            <Nav.Link onClick={onLogout}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
  )
}

export default NavBar
