import React from 'react';
import {useToasts} from 'react-toast-notifications';
import {useHistory} from "react-router-dom";
import {isLoggedIn, login, Path} from "../App";
import './Login.css'
import GoogleLogin from "react-google-login";
import {authClientId} from "../Data/Api";

const Login: React.FunctionComponent = () => {
  const history = useHistory();
  const {addToast} = useToasts();
  
  const onSuccess = (response: any) => {
    login(response.code).then(() => {
      history.push(Path.Root)
    }).catch(() => {
      onFailure()
    })
  }
  
  const onFailure = (response?: any) => {
    addToast('Sorry, login failed', {appearance: 'error'})
    console.log(response)
  }
  
  if (isLoggedIn()) {
    history.push(Path.Root)
    return null
  }
  
  return (
      <div className="auth-wrapper">
        <div className="auth-inner">
          <form>
            <div className='verticalList alignCenter'>
              <h4>Sign In</h4>
              
              <GoogleLogin
                  clientId={authClientId}
                  buttonText="Login with Google"
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  prompt={'consent'}
                  responseType={'code'}
                  accessType={'offline'}
                  cookiePolicy={'single_host_origin'}/>
              
              <div style={{height: '10px'}}/>
            </div>
          </form>
        </div>
      </div>
  )
}

export default Login
