import React from 'react';
import './Root.css';
import NavBar from "./NavBar";
import MyTasks from "../Tasks/MyTasks";
import {Path, PrivateRoute} from "../App";
import PartnerTasks from "../Tasks/PartnerTasks";
import {Redirect, Switch} from "react-router-dom";
import Profile from "../Profile/Profile";

const Root: React.FunctionComponent = () => {
  return (
      <div className='rootContainer'>
        <NavBar/>
        <Switch>
          <PrivateRoute exact path={Path.Tasks.Me} component={MyTasks}/>
          <PrivateRoute exact path={Path.Tasks.Partner} component={PartnerTasks}/>
          <PrivateRoute exact path={Path.Profile} component={Profile}/>
          <Redirect path={Path.Root} to={Path.Tasks.Me}/>
        </Switch>
      </div>
  )
}

export default Root
