import React, {useEffect, useState} from "react";
import {Resource, Task} from "../Data/Model";
import {Spinner} from "react-bootstrap";
import {Api} from "../Data/Api";
import {useToasts} from "react-toast-notifications";
import './Tasks.css'
import TaskDialog from "./TaskDialog";
import {createTask, sortTasks} from "../Data/TaskUtils";
import Button from "react-bootstrap/Button";
import TaskElement from "./TaskElement";

const MyTasks: React.FunctionComponent = () => {
  const {addToast} = useToasts();
  const [tasks, setTasks] = useState<Task[]>([])
  const [resource, setResource] = useState(Resource.Initial)
  const [editing, setEditing] = useState<Task | null>(null)
  const [refreshScheduled, setRefreshScheduled] = useState<boolean>(false)
  
  useEffect(() => {
    scheduleRefresh()
    load()
  })
  
  const scheduleRefresh = () => {
    if (refreshScheduled) return
    
    setRefreshScheduled(true)
    setInterval(() => {
      if (document.hidden) {
        setResource(Resource.Initial)
        load()
      }
    }, 300_000)
  }
  
  const load = () => {
    // TODO pagination
    if (resource !== Resource.Initial) return
    
    setResource(Resource.Loading)
    Api.getTasks((tasks) => {
      setTasks(sortTasks(tasks))
      setResource(Resource.Complete)
    }, () => {
      addToast('Failed to load tasks', {appearance: 'error'})
      setResource(Resource.Error)
    })
  }
  
  const onSave = (task: Task) => {
    let local = tasks.find((t) => t.id === task.id)
    if (local) {
      let index = tasks.indexOf(local)
      tasks[index] = task
    } else {
      tasks.push(task)
    }
    
    setTasks(sortTasks(tasks))
    setEditing(null)
  }
  
  const onDelete = (taskId: string) => {
    setTasks(tasks.filter((task) => task.id !== taskId))
    setEditing(null)
  }
  
  const onDialogClose = () => {
    setEditing(null)
  }
  
  switch (resource) {
    case Resource.Complete: {
      const dialog =
          <TaskDialog
              task={editing}
              onSave={onSave}
              onDelete={onDelete}
              onClose={onDialogClose}/>
      
      if (tasks.length === 0) {
        return <div className='listStateContainer'>
          <p>Welcome to Partner's Accountability!</p>
          <Button variant="outline-primary" onClick={() => setEditing(createTask())}>Create my first task</Button>
          {dialog}
        </div>
      } else {
        return <div className='verticalList'>
          <div className='newTaskButtonWrapper'>
            <Button variant="outline-primary" onClick={() => setEditing(createTask())}>New Task</Button>
          </div>
          {tasks.map((task) => <TaskElement key={task.id} task={task} onClick={() => setEditing(task)}/>)}
          {dialog}
        </div>
      }
    }
    case Resource.Loading: {
      return <div className='listStateContainer'>
        <Spinner animation="border"/>
      </div>
    }
    default:
      return null
  }
}

export default MyTasks
