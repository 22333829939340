import React from "react";
import {Button, Modal} from "react-bootstrap";

interface ConfirmationDialogProps {
  readonly show: boolean
  readonly body: string
  readonly onConfirm: () => void
  readonly onCancel: () => void
}

const ConfirmationDialog: React.FunctionComponent<ConfirmationDialogProps> = (
    {show, body, onConfirm, onCancel}
): JSX.Element => (
    <Modal
        size='sm'
        show={show}
        aria-labelledby='contained-modal-title-vcenter'
        onHide={onCancel}
        centered
    >
      <Modal.Header>
        <Modal.Title>Please Confirm</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>
      
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onCancel}>No</Button>
        <Button variant="primary" onClick={onConfirm}>Yes</Button>
      </Modal.Footer>
    </Modal>
)

export default ConfirmationDialog
