import {LocalDate, ZonedDateTime} from "@js-joda/core";

export interface User {
  readonly id: string
  readonly email: string
  readonly name: string | null
  readonly partnerId: string | null
  readonly avatarUrl: string | null
}

export interface TaskApi {
  readonly id: string
  readonly details: string
  readonly deleted: boolean
  readonly dateStart: string
  readonly dateEnd: string | null
  readonly dateCreated: string
  readonly dateUpdated: string
}

export interface Task {
  readonly id: string
  readonly details: string
  readonly deleted: boolean
  readonly dateStart: LocalDate
  readonly dateEnd: LocalDate | null
  readonly dateCreated: ZonedDateTime
  readonly dateUpdated: ZonedDateTime
  readonly creating?: boolean
  current?: boolean
}

export interface Template {
  readonly id: string
  readonly value: string
}

export enum Resource {
  Initial,
  Loading,
  Complete,
  Error
}
