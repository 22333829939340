import {Task} from "../Data/Model";
import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {Api} from "../Data/Api";
import {useToasts} from "react-toast-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {dateFormat, dateFromLocalDate, dateTime, localDateOf} from "../Core/Util/DateUtils";
import ConfirmationDialog from "../Core/Component/ConfirmationDialog";

interface TaskDialogProps {
  readonly task: Task | null
  readonly onSave: (task: Task) => void
  readonly onDelete: (id: string) => void
  readonly onClose: () => void
}

const TaskDialog: React.FunctionComponent<TaskDialogProps> = ({task, onSave, onDelete, onClose}): JSX.Element => {
  const {addToast} = useToasts();
  const [editing, setEditing] = useState<Task | null>(null)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  
  useEffect(() => {
    setEditing(task)
  }, [task]);
  
  const onDetailsChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!editing) return
    
    const details = e.target.value
    setEditing({...editing, details})
  }
  
  const onDateStartChange = (date: Date | null) => {
    if (!editing || !date) return
    
    const dateStart = localDateOf(date)
    setEditing({...editing, dateStart})
  }
  
  const onEndStartChange = (date: Date | null) => {
    if (!editing) return
    
    const dateEnd = date ? localDateOf(date) : null
    setEditing({...editing, dateEnd})
  }
  
  const canSave = (): boolean => {
    return !saving
  }
  
  const save = (deleted: boolean) => {
    if (!editing) {
      addToast('Oops, something went wrong', {appearance: 'error'})
      return
    }
    
    if (editing.dateEnd != null && editing.dateEnd.isBefore(editing.dateStart)) {
      addToast('Date end can\'t be before start date', {appearance: 'error'})
      return
    }
    
    setSaving(true)
    const dateUpdated = dateTime()
    
    let task = {...editing, dateUpdated, deleted}
    Api.syncTask(task, (responseTask) => {
      setEditing(null)
      setSaving(false)
      addToast(`Task ${deleted ? 'deleted' : 'saved'}`, {appearance: 'success'})
      deleted ? onDelete(task.id) : onSave(responseTask)
    }, () => {
      addToast('You have reached the task limit for the given date', {appearance: 'error'})
      setSaving(false)
    }, () => {
      addToast('Failed to save task', {appearance: 'error'})
      setSaving(false)
    })
  }
  
  const askConfirmationForRemoval = () => {
    setDeleting(true)
  }
  
  const remove = () => {
    setDeleting(false)
    save(true)
  }
  
  const cancelRemoval = () => {
    setDeleting(false)
  }
  
  const keyListener = useCallback((event) => {
    if (event.key === 'Enter' && (event.metaKey || event.ctrlKey) && canSave()) {
      save(false)
    }
  }, [save, canSave]);
  
  useEffect(() => {
    if (task) {
      document.addEventListener("keydown", keyListener, false);
    }
    
    return () => document.removeEventListener("keydown", keyListener, false);
  }, [keyListener, task]);
  
  return (
      <div>
        <Modal
            size="lg"
            show={task != null}
            aria-labelledby="contained-modal-title-vcenter"
            onHide={onClose}
            backdrop='static'
            centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Task Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className='formGroup'>
                <Form.Label>Date start</Form.Label>
                <DatePicker
                    selected={editing ? dateFromLocalDate(editing.dateStart) : new Date()}
                    onChange={onDateStartChange}
                    dateFormat={dateFormat}/>
              </div>
              <div className='formGroup'>
                <Form.Label>Date end (optional)</Form.Label>
                <DatePicker
                    selected={editing?.dateEnd ? dateFromLocalDate(editing.dateEnd) : null}
                    onChange={onEndStartChange}
                    dateFormat={dateFormat}/>
              </div>
              <div className='formGroup'>
                <Form.Label>Details</Form.Label>
                <Form.Control as="textarea" rows={14} defaultValue={editing?.details} onChange={onDetailsChange}/>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {editing?.creating ? null :
                <Button variant="outline-danger" onClick={askConfirmationForRemoval} disabled={saving}>
                  Delete
                </Button>
            }
            <Button variant="primary" onClick={() => save(false)} disabled={!canSave()}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        
        <ConfirmationDialog show={deleting}
                            body='Are you sure you want to delete this task?'
                            onConfirm={remove}
                            onCancel={cancelRemoval}/>
      </div>
  )
}

export default TaskDialog
