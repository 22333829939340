import {Resource, User} from "../Data/Model";
import {useToasts} from "react-toast-notifications";
import React, {ChangeEvent, useEffect, useState} from "react";
import "./PartnerProfile.css";
import {Form, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ConfirmationDialog from "../Core/Component/ConfirmationDialog";
import {authToken} from "../App";
import wretch from "wretch";
import {apiUrl} from "../Data/Api";
import PartnerRequest from "./PartnerRequest";

const PartnerProfile: React.FunctionComponent = () => {
  const {addToast} = useToasts();
  const [removing, setRemoving] = useState(false)
  const [partner, setPartner] = useState<User | null>(null)
  const [resource, setResource] = useState(Resource.Initial)
  const [outgoingRequestResource, setOutgoingRequestResource] = useState(Resource.Initial)
  const [inviteEmail, setInviteEmail] = useState("")
  const [requests, setRequests] = useState<Array<User>>([])
  const [requestsResource, setRequestsResource] = useState(Resource.Initial)
  
  useEffect(() => {
    load(false)
  })
  
  const load = (force: boolean) => {
    if (!force && resource !== Resource.Initial) return
    
    setResource(Resource.Loading)
    authToken().then((token: string) => {
      wretch(`${apiUrl}/partner`)
          .auth(`Bearer ${token}`)
          .get()
          .error(404, _ => {
            setPartner(null)
            setResource(Resource.Complete)
            loadRequests(true)
          })
          .json(response => {
            setPartner(response as User)
            setResource(Resource.Complete)
          })
          .catch(_ => {
            setResource(Resource.Complete)
          })
    })
  }
  
  const loadRequests = (force: boolean) => {
    if (!force && requestsResource !== Resource.Initial) return
    
    setRequestsResource(Resource.Loading)
    authToken().then((token: string) => {
      wretch(`${apiUrl}/partner/request/incoming`)
          .auth(`Bearer ${token}`)
          .get()
          .json(response => {
            setRequests(response as Array<User>)
            setRequestsResource(Resource.Complete)
          })
          .catch(_ => {
            addToast('Failed to load incoming requests', {appearance: 'error'})
            setRequestsResource(Resource.Complete)
          })
    })
  }
  
  const refresh = () => {
    load(true)
  }
  
  const removePartner = () => {
    authToken().then((token: string) => {
      wretch(`${apiUrl}/partner`)
          .auth(`Bearer ${token}`)
          .delete()
          .res(() => {
            addToast('Partner removed', {appearance: 'success'})
            setRemoving(false)
            refresh()
          })
          .catch(() => {
            addToast('Failed to remove partner', {appearance: 'error'})
            setRemoving(false)
          })
    })
  }
  
  const invite = () => {
    if (outgoingRequestResource === Resource.Loading) return
    
    setOutgoingRequestResource(Resource.Loading)
    authToken().then((token: string) => {
      wretch(`${apiUrl}/partner/request/outgoing`)
          .auth(`Bearer ${token}`)
          .post({email: inviteEmail})
          .res(response => {
            switch (response.status) {
              case 200: {
                addToast('Invite sent', {appearance: 'success'})
                break
              }
              case 201: {
                addToast('Partner linked', {appearance: 'success'})
              }
            }
            refresh()
            setOutgoingRequestResource(Resource.Complete)
          })
          .catch(_ => {
            setOutgoingRequestResource(Resource.Complete)
            addToast('User not found', {appearance: 'error'})
          })
    })
  }
  
  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value
    setInviteEmail(email)
  }
  
  if (resource === Resource.Loading) {
    return <div className='listStateContainer'><Spinner animation="border"/></div>
  }
  
  if (resource === Resource.Error) {
    return <div className='listStateContainer'>
      <p>Couldn't load partner</p>
      <Button variant="outline-primary" onClick={() => load(true)}>Retry</Button>
    </div>
  }
  
  const partnerProfile = (user: User) => {
    return <div className='verticalList overflowVisible'>
      <p className='partnerLabel'>Accountability partner</p>
      <div className='horizontalList'>
        {user.avatarUrl
            ? <img className='partnerAvatar' src={user.avatarUrl} alt="Avatar"/>
            : null}
        <div className='verticalList wrapped'>
          <p className='partnerName'>{user.name}</p>
          <p className='partnerEmail'>{user.email}</p>
        </div>
      </div>
      <div className='verticalSpace20'/>
      <div className='horizontalList fromEnd'>
        <Button variant="outline-danger" onClick={() => setRemoving(true)}>Remove</Button>
      </div>
    </div>
  }
  
  const requestsLabel = () => {
    if (requestsResource === Resource.Complete && requests.length === 0) {
      return 'No incoming requests'
    } else {
      return 'Incoming requests'
    }
  }
  
  const noPartner = () => {
    return <div className='verticalContainer'>
      <p className='partnerInviteLabel'>Find your partner</p>
      <div className='horizontalList noWrap'>
        <Form.Control type="email"
                      defaultValue={partner?.email}
                      disabled={partner !== null}
                      onChange={onEmailChange}
                      placeholder="invite.your@partner.com"/>
        <Button className='leftMargin' variant="outline-primary" onClick={invite}
                disabled={outgoingRequestResource === Resource.Loading}>Invite</Button>
      </div>
      <p className='partnerRequestsLabel'>{requestsLabel()}</p>
      {requests.map((user) => <PartnerRequest key={user.id} user={user} onChange={refresh}/>)}
    </div>
  }
  
  return <div className='verticalList alignCenter'>
    <div className='partnerContainer'>
      {partner ? partnerProfile(partner) : noPartner()}
    </div>
    
    <ConfirmationDialog show={removing}
                        body='Are you sure you want to remove your accountability partner?'
                        onConfirm={() => {
                          removePartner()
                        }}
                        onCancel={() => setRemoving(false)}/>
  </div>
}

export default PartnerProfile
