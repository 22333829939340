import React, {useState} from "react";
import {Resource, User} from "../Data/Model";
import Button from "react-bootstrap/Button";
import {authToken} from "../App";
import wretch from "wretch";
import {apiUrl} from "../Data/Api";
import {useToasts} from "react-toast-notifications";

interface PartnerRequestProps {
  readonly user: User,
  readonly onChange: () => void
}

const PartnerRequest: React.FunctionComponent<PartnerRequestProps> = ({user, onChange}): JSX.Element => {
  const {addToast} = useToasts();
  const [resource, setResource] = useState(Resource.Initial)
  
  const accept = () => {
    if (resource === Resource.Loading) return
    
    setResource(Resource.Loading)
    authToken().then((token: string) => {
      wretch(`${apiUrl}/partner/request/outgoing`)
          .auth(`Bearer ${token}`)
          .post({email: user.email})
          .res(response => {
            switch (response.status) {
              case 201: {
                addToast('Invitation accepted', {appearance: 'success'})
                break
              }
              default: {
                addToast('Oops, something went wrong', {appearance: 'error'})
              }
            }
            setResource(Resource.Complete)
            onChange()
          })
          .catch(_ => {
            setResource(Resource.Complete)
            addToast('Oops, something went wrong', {appearance: 'error'})
          })
    })
  }
  
  const decline = () => {
    if (resource === Resource.Loading) return
    
    setResource(Resource.Loading)
    authToken().then((token: string) => {
      wretch(`${apiUrl}/partner/request/incoming/decline`)
          .auth(`Bearer ${token}`)
          .post({email: user.email})
          .res(_ => {
            onChange()
            addToast('Invitation declined', {appearance: 'success'})
            setResource(Resource.Complete)
          })
          .catch(_ => {
            setResource(Resource.Complete)
            addToast('Oops, something went wrong', {appearance: 'error'})
          })
    })
  }
  
  return <div className='horizontalList topMargin spaceBetween'>
    <div className='horizontalList'>
      {user.avatarUrl
          ? <img className='partnerAvatar' src={user.avatarUrl} alt="Avatar"/>
          : null}
      <div className='verticalList wrapped'>
        <p className='partnerName'>{user.name}</p>
        <p className='partnerEmail'>{user.email}</p>
      </div>
    </div>
    <div>
      <Button className='leftMargin' variant="outline-primary" onClick={accept}
              disabled={resource === Resource.Loading}>Accept</Button>
      <Button className='leftMargin' variant="outline-danger" onClick={decline}
              disabled={resource === Resource.Loading}>Decline</Button>
    </div>
  </div>
}

export default PartnerRequest