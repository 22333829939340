//export const apiUrl = 'http://api.localhost:80'
import {authToken} from "../App";
import wretch from "wretch";
import {Task, TaskApi, Template, User} from "./Model";
import {mapTask} from "./TaskUtils";

export const apiUrl = 'https://api.partners-accountability.com'
//export const authClientId = '275505244639-k6gtfevc0qirhrnme782ugj34ahn3l7v.apps.googleusercontent.com'
export const authClientId = '275505244639-iu8hpbscat8011dl4n8nq19k43h50fdp.apps.googleusercontent.com'

export const authApiUrl = 'https://oauth2.googleapis.com/token'
export const authClientSecret = '_8-zxjgs5OyMBRjWoxITIqXT'
export const authCodeGrantType = 'authorization_code'
export const authRefreshGrantType = 'refresh_token'
export const authRedirectUri = 'https://web.partners-accountability.com'

function auth(callback: (token: string) => void) {
  authToken().then((token: string) => {
    callback(token)
  })
}

// TODO support pagination
export class Api {
  
  static getTasks(onSuccess: (tasks: Array<Task>) => void, onError: () => void) {
    auth((token) => {
      wretch(`${apiUrl}/task?limit=50&firstPage=true`)
          .auth(`Bearer ${token}`)
          .get()
          .json(response => onSuccess((response as TaskApi[]).map(api => mapTask(api))))
          .catch(() => onError())
    })
  }
  
  static getPartnerTasks(onSuccess: (tasks: Array<Task>) => void, onNoPartner: () => void, onError: () => void) {
    auth((token) => {
      wretch(`${apiUrl}/task/partner?limit=50&firstPage=true`)
          .auth(`Bearer ${token}`)
          .get()
          .notFound(error => onNoPartner())
          .json(response => onSuccess((response as TaskApi[]).map(api => mapTask(api))))
          .catch(_ => onError())
    })
  }
  
  static syncTask(task: Task, onSuccess: (task: Task) => void, limitReached: () => void, onError: () => void) {
    auth((token) => {
      wretch(`${apiUrl}/task`)
          .auth(`Bearer ${token}`)
          .post(task)
          .error(418, _ => limitReached())
          .json(response => onSuccess(mapTask(response as TaskApi)))
          .catch(() => onError())
    })
  }
  
  static getProfile(onSuccess: (user: User) => void, onError: () => void) {
    auth((token) => {
      wretch(`${apiUrl}/user`)
          .auth(`Bearer ${token}`)
          .get()
          .json(response => onSuccess(response as User))
          .catch(() => onError())
    })
  }
  
  static getTemplates(onSuccess: (templates: Array<Template>) => void, onError: () => void) {
    auth((token) => {
      wretch(`${apiUrl}/template`)
          .auth(`Bearer ${token}`)
          .get()
          .json(response => onSuccess(response as Array<Template>))
          .catch(() => onError())
    })
  }
}
