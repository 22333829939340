import React from 'react';
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import Login from "./Login/Login";
import Root from "./Root/Root";
import {ToastProvider} from 'react-toast-notifications'
import wretch from "wretch";
import {
  authApiUrl,
  authClientId,
  authClientSecret,
  authCodeGrantType,
  authRedirectUri,
  authRefreshGrantType
} from "./Data/Api";

export function login(code: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const form = {
      code: code,
      client_id: authClientId,
      client_secret: authClientSecret,
      grant_type: authCodeGrantType,
      redirect_uri: authRedirectUri
    }
    
    wretch(authApiUrl)
        .formUrl(form)
        .post()
        .json(response => {
          localStorage.setItem('refresh_token', response.refresh_token) // TODO store in an encrypted cookie
          localStorage.setItem('token', response.id_token)
          localStorage.setItem('expiration', (new Date().getTime() / 1000 + response.expires_in).toString())
          resolve()
        })
        .catch((error) => {
          console.log(error)
          clearCredentials()
          reject()
        })
  });
}

function refreshToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem('refresh_token')
    
    const form = {
      refresh_token: refreshToken,
      client_id: authClientId,
      client_secret: authClientSecret,
      grant_type: authRefreshGrantType
    }
    
    wretch(authApiUrl)
        .formUrl(form)
        .post()
        .json(response => {
          localStorage.setItem('token', response.id_token)
          localStorage.setItem('expiration', (new Date().getTime() / 1000 + response.expires_in).toString())
          resolve(response.id_token)
        })
        .catch((error) => {
          console.log(error)
          clearCredentials()
          reject()
        })
  })
}

export function clearCredentials() {
  localStorage.removeItem('token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('expiration')
  
  // TODO route to login with error toast
}

export function isLoggedIn(): boolean {
  return localStorage.getItem('token') != null
}

export function authToken(): Promise<string> {
  const token = localStorage.getItem('token')
  const expiration = localStorage.getItem('expiration')
  
  return new Promise((resolve, reject) => {
    if (token === null || expiration === null) {
      clearCredentials()
      reject()
    }
    
    if (Number(expiration) < new Date().getTime() / 1000) {
      refreshToken().then((newToken: string) => {
        resolve(newToken)
      }).catch(() => {
        clearCredentials()
        reject()
      })
    } else {
      resolve(token!)
    }
  })
}

export const Path = {
  Root: "/",
  Login: "/login",
  Profile: "/profile",
  Tasks: {
    Me: "/tasks/me",
    Partner: "/tasks/partner"
  },
}

export default function App() {
  return (
      <ToastProvider autoDismiss={true} autoDismissTimeout={4000}>
        <HashRouter>
          <Switch>
            <Route exact path={Path.Login} component={Login}/>
            <PrivateRoute path={Path.Root} component={Root}/>
          </Switch>
        </HashRouter>
      </ToastProvider>
  );
}

// @ts-ignore
export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        isLoggedIn()
            ? <Component {...props} />
            : <Redirect to={{
              pathname: '/login',
              state: {from: props.location}
            }}/>
    )}/>
)
