import React, {useEffect, useState} from "react";
import {useToasts} from "react-toast-notifications";
import './Profile.css'
import {Resource, User} from "../Data/Model";
import {Api} from "../Data/Api";
import {Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import PartnerProfile from "./PartnerProfile";

const Profile: React.FunctionComponent = () => {
  const {addToast} = useToasts();
  const [user, setUser] = useState<User>()
  const [resource, setResource] = useState(Resource.Initial)
  
  useEffect(() => {
    load()
  })
  
  const load = () => {
    if (resource !== Resource.Initial) return
    
    setResource(Resource.Loading)
    Api.getProfile((user) => {
      setUser(user)
      setResource(Resource.Complete)
    }, () => {
      addToast('Failed to load user profile', {appearance: 'error'})
      setResource(Resource.Error)
    })
  }
  
  if (resource === Resource.Loading) {
    return <div className='listStateContainer'><Spinner animation="border"/></div>
  }
  
  if (resource === Resource.Error) {
    return <div className='listStateContainer'>
      <p>Couldn't load profile</p>
      <Button variant="outline-primary" onClick={load}>Retry</Button>
    </div>
  }
  
  if (!user) return null
  
  const userProfile = <div className='verticalList alignCenter'>
    {user.avatarUrl
        ? <img className='profileAvatar' src={user.avatarUrl} alt="Avatar"/>
        : null}
    {user.name
        ? <p className='profileName'>{user.name}</p>
        : null}
    <p className='profileEmail'>{user.email}</p>
  </div>
  
  return <div className='verticalList'>
    {userProfile}
    <PartnerProfile/>
  </div>
}

export default Profile
