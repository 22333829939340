import React, {useEffect, useState} from "react";
import {Resource, Task} from "../Data/Model";
import {Spinner} from "react-bootstrap";
import {Api} from "../Data/Api";
import {Path} from "../App";
import {useToasts} from "react-toast-notifications";
import './Tasks.css'
import TaskElement from "./TaskElement";
import Button from "react-bootstrap/Button";
import {useHistory} from "react-router-dom";
import {sortTasks} from "../Data/TaskUtils";

const PartnerTasks: React.FunctionComponent = () => {
  const history = useHistory();
  const {addToast} = useToasts();
  const [tasks, setTasks] = useState<Task[]>([])
  const [resource, setResource] = useState(Resource.Initial)
  const [hasPartner, setHasPartner] = useState<boolean>(true)
  
  useEffect(() => {
    load()
  })
  
  const load = () => {
    if (resource !== Resource.Initial) return
    
    // TODO pagination
    setResource(Resource.Loading)
    Api.getPartnerTasks((tasks) => {
          setTasks(sortTasks(tasks))
          setResource(Resource.Complete)
          setHasPartner(true)
        }, () => {
          setResource(Resource.Complete)
          setHasPartner(false)
        }, () => {
          setResource(Resource.Complete)
          addToast(`Oops, something went wrong`, {appearance: 'error'})
        }
    )
  }
  
  if (!hasPartner) {
    return <div className='listStateContainer'>
      <p>Looks like you don't have a partner yet!</p>
      <Button variant="outline-primary" onClick={() => history.push(Path.Profile)}>Find a partner</Button>
    </div>
  }
  
  switch (resource) {
    case Resource.Complete: {
      return <div className='verticalList'>
        {tasks.map((task) => <TaskElement key={task.id} task={task}/>)}
        {tasks.length === 0 ? <div className='listStateContainer'><p>Your partner has no tasks</p></div> : null}
      </div>
    }
    case Resource.Loading: {
      return <div className='listStateContainer'>
        <Spinner animation="border"/>
      </div>
    }
    default:
      return null
  }
}

export default PartnerTasks
