import {Task} from "../Data/Model";
import React from "react";
import {dateFormatter} from "../Core/Util/DateUtils";

interface TaskProps {
    readonly task: Task
    readonly onClick?: () => void
}

const TaskElement: React.FunctionComponent<TaskProps> = ({task, onClick}): JSX.Element => {
    const formatDate = (): string => {
        const start = dateFormatter.format(task.dateStart)
        const end = task.dateEnd ? ` - ${dateFormatter.format(task.dateEnd)}` : ''
        return start + end
    }

    return (
        <div className={task.current ? 'taskContainer taskContainerCurrent' : 'taskContainer'} onClick={() => {
            if (onClick) onClick()
        }}>
            <p className={task.current ? 'taskDate taskDateCurrent' : 'taskDate'}>{formatDate()}</p>
            <p className='taskDetails'>{task.details}</p>
        </div>
    )
}

export default TaskElement
