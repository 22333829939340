import '@js-joda/timezone';
import {DateTimeFormatter, LocalDate, nativeJs, ZonedDateTime, ZoneOffset} from "@js-joda/core";
import {Locale} from "@js-joda/locale_en-us";

export const dateFormat = "d MMMM yyyy"
export const dateFormatter = DateTimeFormatter.ofPattern(dateFormat).withLocale(Locale.US)

export function dateTime(): ZonedDateTime {
  return ZonedDateTime.now(ZoneOffset.UTC)
}

export function localDate(): LocalDate {
  return LocalDate.now()
}

export function localDateOf(date: Date): LocalDate {
  return LocalDate.from(nativeJs(date))
}

export function dateFromLocalDate(localDate: LocalDate): Date {
  return new Date(localDate.toString())
}
